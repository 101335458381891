var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'EducatorQuestionsList'
          }}},[_vm._v(" Questions ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1)],2),_c('Heading',{attrs:{"heading":"h4","content":_vm.myTitle}}),_c('hr'),_c('el-card',{staticClass:"test-paper mb-4"},[(
        _vm.question &&
          _vm.question.type === 'single' &&
          _vm.question.passage_id > 0 &&
          _vm.question.passage.content
      )?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageIntro":_vm.question.passage.introduction
          ? _vm.question.passage.introduction.intro
          : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.options,"answer":``,"correctAnswer":_vm.getCAnswers(_vm.question.answers),"isCorrect":false,"explanation":_vm.question.explanations && _vm.question.explanations.length > 0
          ? _vm.question.explanations[0].explanation
          : '',"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):(
        _vm.question &&
          _vm.question.type === 'single' &&
          (_vm.question.passage === null || _vm.question.passage.content === null)
      )?_c('MultipleChoice',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.options,"answer":`A`,"correctAnswer":`A`,"isCorrect":false,"explanation":_vm.question.explanations && _vm.question.explanations.length > 0
          ? _vm.question.explanations[0].explanation
          : '',"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):(_vm.question && _vm.question.type === 'math')?_c('Math',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer !== null ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }